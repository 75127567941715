<template>
    <v-data-table
        ref="table"
        :value="value"
        :height="height"
        :loading="localLoading"
        :loading-text="loadingText"
        :headers="columns"
        :items="items"
        :item-key="itemKey"
        :page.sync="page"
        :sort-by.sync="localSortBy"
        :sort-desc.sync="localSortDesc"
        :items-per-page.sync="localPerPage"
        :server-items-length="localTotalServerItems"
        :show-select="showSelect"
        :hide-default-footer="hideDefaultFooter"
        :footer-props="{
            prevIcon: 'fal fa-chevron-left',
            nextIcon: 'fal fa-chevron-right',
            itemsPerPageOptions: [5, 10, 15, 20, 50, 100, 500, 1000, -1]
        }"
        :multi-sort="multiSort"
        @update:options="handleOptionsUpdated"
        @update:items-per-page="handleItemsPerPageUpdated"
        @update:sort-by="handleSortByUpdated"
        @update:sort-desc="handleSortByUpdated"
        @update:page="handlePageUpdated"
        @input="handleInput"
        :show-group-by="showGroupBy"
        :custom-group="groupItems"
        :group-by="localGroupBy"
        :fixed-header="fixedHeader"
        :dense="dense"
    >
        <template v-slot:top v-if="! hideHeader">
            <div class="tw-px-4 tw-py-4 tw-flex tw-items-center tw-justify-between">
                <h3 class="tw-mb-0 font-weight-regular" v-if="! hideCaption">{{ caption || $route.meta.title || $route.meta.label }}</h3>
                <v-spacer/>
                <div class="tw-flex tw-items-center tw-justify-end">
                    <v-text-field
                        solo-inverted
                        flat
                        hide-details
                        label="Search"
                        dense
                        clearable
                        clear-icon="fal fa-times-circle fa-fw"
                        prepend-inner-icon="fal fa-search"
                        @input="search"
                        color="white"
                        v-if="searchable"
                    />
                    <slot name="actions"></slot>
                    <v-menu
                        offset-y
                        :close-on-content-click="false"
                        :min-width="300"
                        left
                        v-model="showFiltersMenu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="filtersUrl"
                                small
                                rounded
                                outlined
                                @click="getFilters"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left dense>far fa-filter fa-sm</v-icon> Filter
                            </v-btn>
                        </template>
                        <v-list class="tw-py-0">
                            <v-list-item v-if="gettingFilters">
                                <v-list-item-title>Loading...</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else class="tw-px-0">
                                <v-card flat class="tw-w-full">
                                    <v-card-text>
                                        <v-autocomplete
                                            :items="filters"
                                            item-text="label"
                                            item-value="column"
                                            label="Filter By"
                                            @change="handleFilterChange"
                                        />
                                        <v-autocomplete
                                            v-if="selectedFilter && selectedFilter.input_type === 'autocomplete'"
                                            :items="selectedFilter.options"
                                            :item-text="selectedFilter.item_text"
                                            :item-value="selectedFilter.item_value"
                                            label="Value"
                                            multiple
                                            chips
                                            deletable-chips
                                            v-model="selectedFilterValue"
                                        />
                                        <v-combobox
                                            v-if="selectedFilter && selectedFilter.input_type === 'combobox'"
                                            label="Value"
                                            multiple
                                            chips
                                            deletable-chips
                                            v-model="selectedFilterValue"
                                        />
                                        <v-menu
                                            v-if="selectedFilter && selectedFilter.input_type === 'date'"
                                            v-model="showFilterDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="dateRangeFilterValueText"
                                                    label="Select Range"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="selectedFilterValue"
                                                no-title
                                                scrollable
                                                range
                                                @change="showFilterDateMenu = false"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            text
                                            @click="showFiltersMenu = false"
                                        >Cancel</v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="applyFilter"
                                        >Apply</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn small @click="refresh" class="ml-2">
                        <v-icon left dense>far fa-sync-alt fa-sm</v-icon> Refresh
                    </v-btn>
                </div>
            </div>
            <slot name="subheader"></slot>
            <div class="tw-p-4 tw-flex tw-items-center" v-if="selectedFilters.length > 0">
                <v-chip
                    v-for="filter in selectedFilters"
                    :key="filter.id"
                    close
                    class="tw-mr-2"
                    @click:close="deleteSelectedFilter(filter)"
                >
                    {{ `${filter.label}: ${filter.value.title}` }}
                </v-chip>
            </div>
            <v-divider></v-divider>
        </template>
        <template v-slot:item="{ item, headers, isSelected, select }">
            <tr
                @mouseover="handleMouseoverRow($event)"
                @mouseout="handleMouseoutRow($event)"
                :class="{'grey lighten-3': isSelected}"
            >
                <template v-for="(header, i) in headers">
                    <td
                        v-if="header.value !== 'actions'"
                        :key="header.value"
                        :align="header.align"
                        class="tw-relative"
                    >
                        <v-checkbox
                            v-if="header.value === 'data-table-select'"
                            :input-value="isSelected"
                            @click.stop="select(isSelected ? false : true)"
                        ></v-checkbox>
                        <slot
                            :name="header.slotName"
                            v-else
                            v-bind:item="item"
                        >{{ leaf(item, header.value) }}</slot>
                        <div
                            v-if="i === (headers.length - 1)"
                            class="actions tw-hidden tw-absolute tw-items-center tw-divide-x tw-divide-gray-400 tw-top-0 tw-right-0"
                        >
                            <template v-for="action in actions">
                                <div
                                    v-if="(action.isEligible && action.isEligible(item)) || ! action.isEligible"
                                    :key="action.label"
                                    class="mr-2 pl-2 tw-flex-grow"
                                >
                                    <a
                                        v-if="action.handler"
                                        @click="action.handler(item)"
                                        class="d-flex align-center tw-text-blue-600 hover:tw-text-blue-800"
                                    >
                                        <span :class="action.icon"></span>
                                        <span class="ml-1 tw-flex-grow tw-whitespace-nowrap">{{ action.label }}</span>
                                    </a>
                                    <a
                                        v-if="action.href"
                                        :href="action.href(item)"
                                        target="_blank"
                                        class="d-flex align-center tw-text-blue-600 hover:tw-text-blue-800"
                                    >
                                        <span :class="action.icon"></span>
                                        <span class="ml-1">{{ action.label }}</span>
                                    </a>
                                    <v-menu v-if="action.dropdown">
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                v-bind="attrs"
                                                v-on="on"
                                                class="d-flex align-center tw-text-blue-600 hover:tw-text-blue-800"
                                            >
                                                <span :class="action.icon"></span>
                                                <span class="ml-1">{{ action.label }}</span>
                                            </a>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                v-for="(item, i) in action.dropdown(item)"
                                                :key="i"
                                                :href="item.href"
                                                target="_blank"
                                            >
                                                <v-list-item-icon v-if="item.icon">
                                                    <v-icon v-text="item.icon"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.label"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                        </div>
                    </td>
                </template>
            </tr>
        </template>
        <template
                v-for="column in columns"
                v-slot:[column.slotName]="item"
            >
            <slot :name="`item.${column.value}`" v-bind:item="item"></slot>
        </template>
        <template v-slot:group.header="{ group, groupBy, headers, toggle, isOpen, items }">
            <slot name="group-header" v-bind:item="{ group, groupBy, headers, toggle, isOpen, items }">
                <th :colspan="headers.length" @click="toggle" class="tw-cursor-pointer tw-sticky" style="background-color: #eee;top:48px;z-index: 1;" :ref="group">
                    <div class="tw-flex tw-items-center">
                        <span v-if="isOpen" class="fal fa-chevron-up fa-sm"></span>
                        <span v-else class="fal fa-chevron-down fa-sm"></span>
                        <div class="ml-2 tw-flex-1">
                            <slot name="group-header-label" v-bind:item="{ group, groupBy, items }">
                                {{ group }}
                            </slot>
                        </div>
                    </div>
                </th>
            </slot>
        </template>
        <template v-slot:body.append="item">
            <slot name="body.append" v-bind:item="item"></slot>
        </template>
        <template v-slot:footer="item">
            <slot name="footer" v-bind:data="item"></slot>
        </template>
    </v-data-table>
</template>

<script>    
    import debounce from 'lodash.debounce';
    import isEqual from 'lodash.isequal';
    import Http from '@/utils/httpClient';

    export default {
        props: {
            value: null,
            url: {
                type: String,
                default: null,
            },
            data: {
                type: Array,
                default() {
                    return [];
                }
            },
            params: {
                type: Object,
                default() {
                    return {};
                },
            },
            columns: {
                type: Array,
                required: true,
            },
            actions: {
                type: Array,
                default() {
                    return [];
                }
            },
            loadingText: {
                type: String,
                default: 'Loading...'
            },
            caption: {
                type: String,
                default: null,
            },
            hideCaption: {
                type: Boolean,
                default: false,
            },
            showSelect: {
                type: Boolean,
                default: false,
            },
            hideHeader: {
                type: Boolean,
                default: false,
            },
            hideDefaultFooter: {
                type: Boolean,
                default: false,
            },
            height: {
                type: String,
                default: 'calc(100vh - 196px)',
            },
            perPage: {
                type: Number,
                default: 15,
            },
            selectableKey: {
                type: String,
                default: 'isSelectable'
            },
            itemKey: {
                type: String,
                default: 'id',
            },
            showGroupBy: {
                type: Boolean,
                default: false,
            },
            groupBy: {
                type: String,
                default: null,
            },
            parentClosed: {
                type: Boolean,
                default: false,
            },
            fixedHeader: {
                type: Boolean,
                default: true,
            },
            dense: {
                type: Boolean,
                default: false,
            },
            searchable: {
                type: Boolean,
                default: true,
            },
            filtersUrl: {
                type: String,
                default: null,
            },
            multiSort: {
                type: Boolean,
                default: false,
            },
            sortBy: {
                type: String,
                default: null,
            },
            sortDesc: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            totalServerItems: {
                type: Number,
                default: -1,
            }
        },
        data() {
            return {
                localLoading: false,
                gettingFilters: false,
                showFiltersMenu: false,
                showFilterDateMenu: false,
                filters: [],
                selectedFilters: [],
                selectedFilter: null,
                selectedFilterValue: null,
                page: 1,
                total: 0,
                localTotalServerItems: -1,
                items: [],
                sorters: null,
                q: null,
                localGroupBy: null,
                localPerPage: 15,
                localSortBy: this.sortBy,
                localSortDesc: this.sortDesc,
                options: null,
            }
        },
        computed: {
            dateRangeFilterValueText() {
                if (Array.isArray(this.selectedFilterValue)) {
                    return this.selectedFilterValue.join(' ~ ');
                }

                return null;
            },
        },
        watch: {
            url() {
                this.init();
            },
            data() {
                this.init();
            },
            parentClosed() {
                this.init();
            },
            params(newParams, oldParams) {
                if (!isEqual(newParams, oldParams)) {
                    this.init();
                }
            },
            selectedFilters() {
                this.init();
            },
            loading(loading) {
                this.localLoading = loading;
            },
            totalServerItems(total) {
                this.localTotalServerItems = total;
            }
        },
        mounted() {
            this.page = parseInt(this.$route.query.page, 10) || 1;
            this.localTotalServerItems = this.totalServerItems;

            if (this.$route.query.per_page) {
                this.localPerPage = parseInt(this.$route.query.per_page, 10);
            } else if (this.params.per_page) {
                this.localPerPage = this.params.per_page;
            } else {
                this.localPerPage = this.perPage;
            }

            this.init(true);
        },
        methods: {
            handleOptionsUpdated(options) {
                this.options = options;

                // this.localPerPage = options.itemsPerPage;
                // this.page = options.page;
                // this.sortBy = options.sortBy.length ? options.sortBy[0] : null;
                // //this.localGroupBy = options.groupBy;
                // //console.log(options.groupBy);
                // this.sorters = options.sortBy.map((item, key) => ({ column: item, direction: options.sortDesc[key] ? 'desc' : 'asc' }));

                // // if (this.localGroupBy.length) {
                // //     this.localPerPage = -1;
                // // }

                // this.init();
            },
            handlePageUpdated(page) {
                this.page = page;

                if (
                    (this.$route.query.page && parseInt(this.$route.query.page, 10) !== page) ||
                    !this.$route.query.page
                ) {
                    this.$router.push({
                        name: this.$route.name,
                        params: this.$route.params,
                        query: Object.assign({}, this.$route.query, {
                            page,
                        }),
                    });

                    this.$emit('update:page', page);
                }

                if (!this.localLoading) {
                    this.init();
                }
            },
            handleItemsPerPageUpdated(number) {
                if (this.hideDefaultFooter) {
                    this.localPerPage = -1;
                    return;
                }

                this.localPerPage = number;

                if (this.$route.query.per_page && parseInt(this.$route.query.per_page, 10) !== number) {
                    this.$router.push({
                        name: this.$route.name,
                        params: this.$route.params,
                        query: Object.assign({}, this.$route.query, {
                            per_page: number,
                        }),
                    });
                }

                if (!this.localLoading) {
                    this.init();
                }
            },
            handleSortByUpdated() {
                this.sorters = this.options.sortBy.map((item, key) => ({ column: item, direction: this.options.sortDesc[key] ? 'desc' : 'asc' }));

                if (!this.localLoading && this.localPerPage !== -1) {
                    this.init();
                }
            },
            handleInput(data) {
                this.$emit('input', data);
            },
            init(toggleGroups = false) {
                if (this.parentClosed) {
                    return;
                }

                if (this.data.length > 0) {
                    this.items = this.data;
                    this.total = this.items.length;
                    this.localGroupBy = this.groupBy;

                    if (toggleGroups === true) {
                        this.toggleGroups();
                    }
                }
                
                if (this.url && !this.url.includes('undefined')) {
                    this.localLoading = true;
                    this.$emit('localLoading', true);
                    Http.get(this.url, {
                            params: this.getParams(),
                        })
                        .then(({ data }) => {
                            this.localLoading = false;
                            this.items = data.data;

                            if (this.localPerPage !== -1) {
                                this.localTotalServerItems = data.meta ? data.meta.total : this.items.length;
                            }

                            this.localGroupBy = this.groupBy;
                            this.$emit('ajaxSuccess', data);

                            if (toggleGroups === true) {
                                this.toggleGroups();
                            }
                        })
                        .catch(() => {
                            this.localLoading = false;
                        })
                }

                const wrapper = this.$refs.table.$el.querySelector('.v-data-table__wrapper');

                wrapper.addEventListener('scroll', (e) => {
                    const actions = e.target.querySelectorAll('.actions');

                    actions.forEach((action) => action.classList.add('tw-hidden'));
                });
            },
            getParams() {
                const params = {
                    per_page: this.localPerPage,
                    sorters: this.sorters,
                    page: this.page,
                    q: this.q,
                    filters: this.selectedFilters.map((filter) => ({ column: filter.column, value: filter.value.id })),
                }

                return Object.keys(Object.assign(params, this.params))
                    .filter((key) => params[key])
                    .reduce((obj, key) => {
                        obj[key] = params[key];
                        return obj;
                    }, {});
            },
            toggleGroups() {
                this.$nextTick(() => {
                    const refs = this.$refs;

                    for (const ref in refs) {
                        if (Object.prototype.hasOwnProperty.call(refs, ref) && refs[ref] && typeof refs[ref].click === 'function') {
                            refs[ref].click();
                        }
                    }
                })
            },
            groupItems(items, groupBy) {
                const key = groupBy[0]
                const groups = []

                for (let i = 0; i < items.length; i += 1) {
                    const item = items[i]
                    const val = this.leaf(item, key)
                    const group = groups.find((i) => i.name === val);

                    if (!group) {
                        groups.push({
                            name: val,
                            items: [item],
                        })
                    } else {
                        group.items.push(item);
                    }
                }

                return groups
            },
            itemHasActions(item) {
                return this.actions.filter((action) => (action.isEligible && action.isEligible(item)) || !action.isEligible).length !== 0;
            },
            search: debounce(function (q) {
                this.q = q;
                this.init();
                this.$emit('search', q);
            }, 1000),
            leaf(obj, path) {
                return path.split('.').reduce((value, el) => {
                    if (value) {
                        return value[el] || '--';
                    }

                    return '--';
                }, obj);
            },
            handleMouseoverRow(e) {
                e.stopPropagation();
                const parent = e.target.closest('tr');
                const actions = parent.querySelector('.actions');
                const parentHeight = parent.offsetHeight;

                actions.classList.remove('tw-hidden');
                actions.classList.add('tw-flex');
                actions.style.backgroundColor = '#eee';
                actions.style.height = `${parentHeight - 1}px`;
            },
            handleMouseoutRow(e) {
                e.stopPropagation();
                const parent = e.target.closest('tr');
                const actions = parent.querySelector('.actions');

                actions.classList.add('tw-hidden');
                actions.classList.remove('tw-flex');
            },
            refresh() {
                this.init();
                this.$emit('refresh');
            },
            getFilters() {
                this.gettingFilters = true;
                this.selectedFilterValue = null;
                this.selectedFilter = {};
                Http.get(this.filtersUrl)
                    .then(({ data }) => {
                        this.filters = data;
                        this.gettingFilters = false;
                    })
                    .catch(() => {
                        this.gettingFilters = false;
                    })
            },
            handleFilterChange(column) {
                this.selectedFilter = this.filters.find((filter) => filter.column === column);

                if (this.selectedFilter.options) {
                    this.selectedFilter.options = this.selectedFilter.options.filter((option) => {
                        const index = this.selectedFilters.findIndex((filter) => filter.value.id === option.id);

                        return index === -1;
                    });
                }
            },
            applyFilter() {
                if (!this.selectedFilterValue) {
                    return;
                }

                const id = Math.random();

                if (
                    Array.isArray(this.selectedFilterValue) &&
                    Array.isArray(this.selectedFilter.options)
                ) {
                    this.selectedFilterValue.forEach((value) => {
                        const selectedValue = this.selectedFilter.options.find((option) => option.id === value);

                        this.selectedFilters.push({
                            id,
                            label: this.selectedFilter.label,
                            column: this.selectedFilter.column,
                            value: selectedValue,
                        });
                    });
                } else if (this.selectedFilter.input_type === 'date') {
                    this.selectedFilters.push({
                        id,
                        label: this.selectedFilter.label,
                        column: this.selectedFilter.column,
                        value: {
                            title: this.dateRangeFilterValueText,
                            id: this.selectedFilterValue,
                        },
                    });
                } else {
                    this.selectedFilters.push({
                        id,
                        label: this.selectedFilter.label,
                        column: this.selectedFilter.column,
                        value: {
                            title: this.selectedFilterValue,
                            id: this.selectedFilterValue,
                        },
                    });
                }

                this.showFiltersMenu = false;
            },
            deleteSelectedFilter(filter) {
                const index = this.selectedFilters.findIndex((item) => item.id === filter.id);

                this.selectedFilters.splice(index, 1);
            }
        }
    }
</script>
<style>
    .v-data-table table {
        position: relative;
    }
</style>
